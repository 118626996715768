.search-box {
  background-color: #272a37;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.custom-select {
  background-color: #272a37;
  color: white;
  border-radius: 15px;
  width: 150px;
  height: 40px;
  font-size: 14px;
  padding: 5px;
}

.search {
  height: 40px;
}

.search-btn {
  background: none;
  border: none;
}

@media (max-width: 900px) {
  .search-box {
    display: flex;
  }

  .form-select {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-btn img {
    height: 30px;
    width: 30px;
  }

  .icon-wrapper {
    margin-bottom: 10px;
  }
}
